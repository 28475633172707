import axios from "axios"
import { Buffer } from 'buffer'
import hashJs from 'hash.js'
// import saslPrep from "saslprep"
import pbkdf2 from 'pbkdf2'

export default class Api {
	static instance = null

	constructor(url) {
		this.baseUrl = url
		this.client = axios.create({ baseURL: url })
		this.handlers = {}
		this.interval = null
		this.token = sessionStorage.getItem('auth-token')

		if (this.token != null)
			this.do_check_login()
				.then(() => {
					this.interval = window.setInterval(() => this.do_check_login(), 30000)
				})
	}

	do_change_password(password, token) {
		return new Promise((resolve, reject) => {
			let password_digest = Api.generate_password(password)

			this.client.post('account/recover-password/', {
				password: password_digest,
				token
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				reject()
			})
		})
	}

	do_check_login() {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		let self = this
		return new Promise((resolve, reject) => {
			this.client.get('auth/', {
				headers: { 'Authorization': this.token }
			})
			.then(response => {
				self.update_auth(response)
				resolve(response)
			})
			.catch(() => {
				this.handle_disconnection()
				reject()
			})
		})
	}

	do_check_password(login, password) {
		return new Promise((resolve, reject) => {
			this.perform_login(login, password)
				.then(([response, token]) => {
					this.perform_logout(token)
						.finally(() => {
							resolve(response)
						})
				})
				.catch(error => {
					reject(new Error("Mauvais login / password"), { cause: error })
				})
		})
	}

	do_contact_me(name, email, phone_number) {
		return new Promise((resolve, reject) => {
			this.client.post('support/contact/', {
				'email': email,
				'name': name,
				'phone_number': phone_number
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				reject()
			})
		})
	}

	do_count_people() {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			this.client.get('people/', {
				headers: { 'Authorization': this.token }
			})
			.then(response => {
				resolve(response.data.total_rows)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_create_people(people, uuid) {
		if (uuid == null && this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			let config = {}
			if (uuid != null)
				config['params'] = {
					'uuid': uuid
				}
			else
				config['headers'] = {
					'Authorization': this.token
				}

			this.client.post('people/', people, config)
				.then(response => {
					resolve(response.data)
				})
				.catch(() => {
					this.do_check_login()
						.finally(() => reject())
				})
		})
	}

	do_delete_account() {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			this.client.delete('account/', {
				headers: { 'Authorization': this.token }
			})
			.then(response => {
				this.handle_disconnection()
				resolve(response.data)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_delete_people(people) {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			this.client.delete(`people/?id=${people.id}`, {
				headers: { 'Authorization': this.token }
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_get_user_info() {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		let self = this
		return new Promise((resolve, reject) => {
			this.client.get('account/', {
				headers: { 'Authorization': this.token }
			})
			.then(response => {
				self.update_auth(response)
				resolve(response.data.account)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_get_user_info_by_uuid(uuid) {
		return new Promise((resolve, reject) => {
			this.client.get('account/company-name/', {
				headers: { 'Authorization': this.token },
				params: { 'uuid': uuid}
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				reject()
			})
		})
	}

	do_get_qr_code() {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			this.client.get('account/qr-code/', {
				headers: {
					'Authorization': this.token
				},
				responseType: 'blob'
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_import_people(data) {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			this.client.post('people/import/', data, {
				headers: {
					'Authorization': this.token,
					'Content-Type': 'text/csv'
				}
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_list_peoples(options) {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			this.client.get('people/', {
				headers: { 'Authorization': this.token },
				params: options
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_login(login, password) {
		return new Promise((resolve, reject) => {
			this.perform_login(login, password)
				.then(([response, token]) => {
					this.token = token
					sessionStorage.setItem('auth-token', this.token)
					this.interval = window.setInterval(() => this.do_check_login(), 30000)
					this.handle_connection()
					resolve(response)
				})
				.catch(error => {
					this.token = null
					sessionStorage.removeItem('auth-token')
					reject(new Error("Error in post first message"), { cause: error })
				})
		})
	}

	do_log_out() {
		if (this.token === null)
			return Promise.resolve()

		return this.perform_logout(this.token)
			.finally(() => {
				this.handle_disconnection()
				sessionStorage.removeItem('auth-token')
			})
	}

	do_lost_password(email) {
		return new Promise((resolve, reject) => {
			this.client.post('account/lost-password/', {
				email: email
			})
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(new Error("Error while retrieving lost password"), { cause: error })
			})
		})
	}

	do_send_message(message) {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			this.client.post('send-message/', message, {
				headers: { 'Authorization': this.token }
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_sign_up(info) {
		return new Promise((resolve, reject) => {
			let password_digest = Api.generate_password(info.password)

			this.client.post('account/', {
				first_name: info.first_name,
				last_name: info.last_name,
				email: info.email,
				password: password_digest,
				company_name: info.company_name,
				phone_number: info.phone_number
			})
			.then(() => {
				resolve()
			})
			.catch(error => {
				reject(new Error("Error while creating new account"), { cause: error })
			})
		})
	}

	do_update_password(new_password) {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			let password_digest = Api.generate_password(new_password)

			this.client.put('account/?password', password_digest, {
				headers: {
					'Authorization': this.token,
					'Content-Type': 'text/plain'
				}
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	do_update_people(people) {
		if (this.token == null)
			return Promise.reject(new Error('Not connected'));

		return new Promise((resolve, reject) => {
			this.client.put('people/', people, {
				headers: { 'Authorization': this.token }
			})
			.then(response => {
				resolve(response.data)
			})
			.catch(() => {
				this.do_check_login()
					.finally(() => reject())
			})
		})
	}

	static get_instance() {
		return Api.instance
	}

	static generate_password(new_password) {
		let password = new_password // saslPrep(info.password)
		let raw_salt = Api.generate_random(32)

		let round = 512
		let start = Date.now()
		let salted_password = pbkdf2.pbkdf2Sync(password, raw_salt, round, 32, 'sha256')
		let elapsed = Date.now() - start

		while (round < 16777216 && elapsed < 500) {
			round *= 2
			start = Date.now()
			salted_password = pbkdf2.pbkdf2Sync(password, raw_salt, round, 32, 'sha256')
			elapsed = Date.now() - start
		}

		let client_key = Api.hmacSha256('Client Key', salted_password)
		let server_key = Api.hmacSha256('Server Key', salted_password)

		let b64_raw_salt = raw_salt.toString('base64')
		let b64_client_key = Buffer.from(client_key).toString('base64')
		let b64_server_key = Buffer.from(server_key).toString('base64')

		return `SCRAM-SHA-256$${round}:${b64_raw_salt}$${b64_client_key}:${b64_server_key}`
	}

	static generate_random(size) {
		return Buffer.from([...Array(size)].map(() => Math.floor(Math.random() * 256)))
	}

	handle_connection() {
		if ('connected' in this.handlers)
			for (let handler of this.handlers['connected'])
				handler()
	}

	handle_disconnection() {
		this.token = null
		window.clearInterval(this.interval)
		this.interval = null

		if ('disconnected' in this.handlers)
			for (let handler of this.handlers['disconnected'])
				handler()
	}

	static hmacSha256 = (message, password) => {
		let hmac = hashJs.hmac(hashJs.sha256, password)
		hmac.update(message)
		return hmac.digest()
	}

	has_token() {
		return this.token != null
	}

	perform_login(login, password) {
		return new Promise((resolve, reject) => {
			const parseAttribute = (message, attribute) => {
				for (let attr of message.split(','))
					if (attribute == attr.substring(0, 2))
						if (attr.charAt(attr.length - 1) == '"')
							return attr.substring(2).slice(0, -1)
						else
							return attr.substring(2)
				return null
			}

			const sha256 = (message) => {
				let hash = hashJs.sha256()
				hash.update(message)
				return hash.digest()
			}

			const xor_string = (a, b) => {
				let result = Buffer.allocUnsafe(a.length)
				for (let i = 0, n = a.length; i < n; i++)
					result[i] = a[i] ^ b[i]
				return result
			}

			let client_nonce = Api.generate_random(32).toString('base64')
			let first_message_bare = `n=${login},r=${client_nonce}`
			let first_message = 'n,,' + first_message_bare

			this.client.post('auth/', first_message, {
				headers: { 'Content-Type': 'text/plain' }
			})
			.then(response => {
				let server_first_message = response.data

				let server_nonce = parseAttribute(server_first_message, 'r=')
				let encoded_salt = parseAttribute(server_first_message, 's=')
				let salt = Buffer.from(encoded_salt, 'base64')
				let round = parseInt(parseAttribute(server_first_message, 'i='))

				let salt_password = pbkdf2.pbkdf2Sync(password, salt, round, 32, 'sha256')

				let client_last_message_without_proof = `c=biws,r=${server_nonce}`
				let client_key = Api.hmacSha256('Client Key', salt_password)
				let stored_key = sha256(client_key)

				let auth_message = `${first_message_bare},${server_first_message},${client_last_message_without_proof}`
				let client_signature = Api.hmacSha256(auth_message, stored_key)
				let client_proof = xor_string(client_key, client_signature)

				let client_last_message = `${client_last_message_without_proof},p=${client_proof.toString('base64')}`

				this.client.post('auth/', client_last_message, {
					headers: { 'Content-Type': 'text/plain' }
				})
				.then(response => {
					let token = response.headers.authorization;
					let server_final_message = response.data

					let server_signature_received = parseAttribute(server_final_message, 'v=')
					server_signature_received = Buffer.from(server_signature_received, 'base64')

					let server_key = Api.hmacSha256('Server Key', salt_password)
					let server_signature = Api.hmacSha256(auth_message, server_key)

					if (Buffer.compare(server_signature_received, Buffer.from(server_signature)) == 0)
						resolve([response, token])
					else
						reject(new Error("Authentication Ok but server may be compromised"))
				})
				.catch(error => {
					reject(new Error("Last message error"), { cause: error })
				})
			})
			.catch(error => {
				reject(new Error("Error in post first message"), { cause: error })
			})
		})
	}

	perform_logout(token) {
		return this.client.delete('auth/', {
			headers: { 'Authorization': token }
		})
	}

	register_handler(event, handler) {
		if (event in this.handlers)
			this.handlers[event].push(handler)
		else
			this.handlers[event] = [ handler ]
	}

	update_auth(response) {
		if (response.headers.authorization != this.token) {
			this.token = response.headers.authorization
			sessionStorage.setItem('auth-token', this.token)
		}
	}

	static init(url) {
		Api.instance = new Api(url)
	}
}
