<template>
	<header id="header" />

	<div id="main" class="customer">
		<v-form :disabled="in_progress" @submit.prevent="do_submit">
			<v-container>
				<v-row dense>
					<v-alert id="alert" closable :color="alert.color" :text="alert.message" :type="alert.type" v-if="alert.show" v-model="alert.show" />
				</v-row>
				<v-row dense>
					<v-col cols="auto" md="6" sm="3">
						<v-text-field clearable label="Prénom" outlined :rules="rules.first_name" v-model="user_info.first_name" />
					</v-col>
					<v-col cols="auto" md="6" sm="3">
						<v-text-field clearable label="Nom" outlined :rules="rules.last_name" v-model="user_info.last_name" />
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="auto" md="12" sm="6">
						<v-text-field clearable label="Email" outlined :rules="rules.email" v-model="user_info.email" />
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="auto" md="12" sm="6">
						<v-text-field clearable label="Numéro de téléphone" outlined :rules="rules.phone_number" v-model="user_info.phone_number" />
					</v-col>
				</v-row>
				<v-row dense>
					Les données sont à destination de {{ this.company_name }}.
				</v-row>
				<v-row>
					<v-spacer />
						<v-btn disabled size="large" text="En cours…" variant="flat" v-if="in_process" />
						<v-btn size="large" text="Valider" type="submit" variant="flat" v-else />
					<v-spacer />
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<style>
.customer {
	margin-left: auto;
	margin-right: auto;
	padding: 1em;
	width: 60%;
}
</style>

<script>
	import Api from '@/Api.js'

	export default {
		data() {
			return {
				alert: {
					color: null,
					message: null,
					show: false,
					type: null
				},
				company_name: null,
				in_progress: false,
				rules: {
					email: [
						email => !email || email.match(/^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : "Adresse email malformé",
						value => value && value.length > 0 ? true : "Doit avoir au moins 1 caractère"
					],
					first_name: [
						value => value && value.length > 0 ? true : "Doit avoir au moins 1 caractère"
					],
					last_name: [
						value => value && value.length > 0 ? true : "Doit avoir au moins 1 caractère"
					],
					phone_number: [
						value => value && value.length > 0 ? true : "Doit avoir au moins 1 caractère"
					]
				},
				user_info: {
					email: null,
					first_name: null,
					last_name: null,
					phone_number: null
				},
				valid_form: false
			}
		},
		methods: {
			do_submit() {
				this.in_progress = true

				let api = Api.get_instance()
				api.do_create_people(this.user_info, this.$route.params.uuid)
					.then(() => {
						this.show_success('Les informations ont étés crées')
					})
					.catch(() => {
						this.show_error('Une erreur s\'est produite')
					})
					.finally(() => {
						this.in_progress = false
					})
			},
			show_error(msg) {
				this.alert.color = 'red'
				this.alert.message = msg
				this.alert.show = true
				this.alert.type = 'error'
			},
			show_success(msg) {
				this.alert.color = 'green'
				this.alert.message = msg
				this.alert.show = true
				this.alert.type = 'success'
			}
		},
		mounted() {
			let api = Api.get_instance()
			api.do_get_user_info_by_uuid(this.$route.params.uuid)
				.then(response => {
					this.company_name = response.company
				})
		},
		name: 'CustomerView'
	}
</script>
