<template>
	<v-data-table-server class="contact" :headers="headers" :items="peoples" :items-length="total_rows" item-value="email" :loading="loading" multi-sort :server-items-length="total_rows" @update:options="fetch_data" v-model:items-per-page="options.itemsPerPage">
		<template v-slot:top>
			<v-toolbar class="contact-toolbar">
				<v-dialog v-model="dialog.delete.show" max-width="640px">
					<v-card title="Supprimer un contact">
						<v-card-text>
							Voulez-vous supprimer le contact suivant : {{ contact.first_name }} {{ contact.last_name }} ?
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn @click="delete_contact()" color="purple" :disabled="dialog.delete.in_progress" size="large" :text="dialog.delete.delete_button" variant="flat" />
							<v-btn @click="cancel_delete_contact()" color="purple" size="large" text="Annuler" variant="outlined" />
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialog.edit.show" max-width="640px">
					<v-card :title="dialog.edit.title">
						<v-card-text>
							<v-alert closable color="red" :text="dialog.edit.alert_message" type="error" v-model="dialog.edit.alert" />
							<v-form class="contact-form" v-model="dialog.edit.valid">
								<v-container>
									<v-row dense>
										<v-col cols="auto" md="6" sm="3">
											<v-text-field clearable label="Prénom" outlined :rules="rules.first_name" v-model="contact.first_name" />
										</v-col>
										<v-col cols="auto" md="6" sm="3">
											<v-text-field clearable label="Nom" outlined :rules="rules.last_name" v-model="contact.last_name" />
										</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="auto" md="12" sm="6">
											<v-text-field clearable label="Email" outlined :rules="rules.email" v-model="contact.email" />
										</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="auto" md="12" sm="6">
											<v-text-field clearable label="Numéro de téléphone" outlined :rules="rules.phone_number" v-model="contact.phone_number" />
										</v-col>
									</v-row>
								</v-container>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn @click="create_new_contact()" color="purple" :disabled="dialog.edit.in_progress" size="large" :text="dialog.edit.create_button" variant="flat" v-show="dialog.edit.create" />
							<v-btn @click="update_contact()" color="purple" :disabled="dialog.edit.in_progress" size="large" :text="dialog.edit.update_button" variant="flat" v-show="dialog.edit.update" />
							<v-btn @click="cancel_new_contact()" color="purple" size="large" text="Annuler" variant="outlined" />
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialog.import.show" max-width="640px">
					<v-card title="Importer des contacts">
						<v-card-text>
							Pour importer une base de données, il suffit de joindre votre fichier en format .csv.<br />
							Si vous avez un fichier Excel (.xls) ou Google Sheet (.gsheet). Il sera nécessaire de convertir ce fichier en .csv avant de le joindre.<br />
							Vous pouvez convertir votre document depuis l'onglet "exporter" ou "télécharger" de votre application.<br />
							Attention, pour que l'importation soit réussie, votre tableau doit comporter 5 colonnes titrées : Nom, Prénom, Email, Téléphone.
							<v-file-input accept="*.csv" clearable label="Choisir un fichier à importer" :rules="rules.size" show-size variant="outlined" v-model="dialog.import.file" />
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn @click="import_contact()" color="purple" :disabled="dialog.import.in_progress" size="large" :text="dialog.import.message" variant="flat" />
							<v-btn @click="cancel_import_contact()" color="purple" size="large" text="Annuler" variant="outlined" />
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-alert closable :color="alert.color" :text="alert.message" :type="alert.type" v-if="alert.show" v-model="alert.show" />
				<v-spacer v-else />
				<v-text-field append-icon="mdi-magnify" clearable hide-details label="Rechercher" outlined v-model="search" @click:clear="fetch_data(this.options)" @input="delayed_search()" @keypress.enter="fetch_data(this.options)" />
			</v-toolbar>
		</template>

		<template v-slot:bottom>
			<v-toolbar class="contact-toolbar">
				<v-spacer />
				<v-btn @click="show_create_new_contact()">Ajouter un contact</v-btn>
				<v-spacer />
				<v-btn @click="show_import_contact()" >Importer des contacts</v-btn>
				<v-spacer />
			</v-toolbar>
		</template>

		<template v-slot:[`item.actions`]="{ item }">
			<v-tooltip location="top">
				<template v-slot:activator="{ on, attrs }">
					<v-icon class="mr-2" @click="show_update_contact(item)" small v-bind="attrs" v-on="on">mdi-pencil</v-icon>
				</template>
				<span>Éditer un contact</span>
			</v-tooltip>
			<v-tooltip location="top">
				<template v-slot:activator="{ on, attrs }">
					<v-icon class="mr-2" @click="show_delete_contact(item)" small v-bind="attrs" v-on="on">mdi-delete</v-icon>
				</template>
				<span>Supprimer un contact</span>
			</v-tooltip>
		</template>
	</v-data-table-server>
</template>

<style>
.contact-form {
	margin-bottom: 0px;
}
.contact-toolbar {
	background-color: white;
}
</style>

<script>
	import Api from '@/Api.js'

	export default {
		data() {
			return {
				alert: {
					color: null,
					message: null,
					show: false,
					type: null
				},
				contact: {
					email: null,
					first_name: null,
					id: null,
					last_name: null,
					phone_number: null
				},
				delay: null,
				dialog: {
					delete: {
						delete_button: null,
						in_progress: false,
						show: false
					},
					edit: {
						alert: false,
						alert_message: null,
						create: false,
						create_button: "Créer",
						in_progress: false,
						message: "Créer",
						show: false,
						title: null,
						update: false,
						update_button: "Modifier",
						valid: false
					},
					import: {
						alert: false,
						alert_message: null,
						file: null,
						in_progress: false,
						message: "Importer",
						show: false
					}
				},
				headers: [{
					align: 'start',
					key: 'first_name',
					sortable: true,
					title: 'Prénom'
				}, {
					align: 'start',
					key: 'last_name',
					sortable: true,
					title: 'Nom'
				}, {
					align: 'start',
					key: 'email',
					sortable: true,
					title: 'Email'
				}, {
					align: 'start',
					key: 'phone_number',
					sortable: true,
					title: 'Numéro de téléphone'
				}, {
					align: 'center',
					key: 'actions',
					sortable: false,
					title: 'Édition'
				}],
				loading: false,
				options: {
					itemsPerPage: 10,
					sortBy: [],
					page: 1
				},
				peoples: [],
				rules: {
					email: [
						email => !email || email.match(/^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : "Adresse email malformé",
						value => !value || value.length > 0 ? true : "Doit avoir au moins 1 caractère"
					],
					first_name: [
						value => !value || value.length > 0 ? true : "Doit avoir au moins 1 caractère"
					],
					last_name: [
						value => !value || value.length > 0 ? true : "Doit avoir au moins 1 caractère"
					],
					phone_number: [
						value => !value || value.length > 0 ? true : "Doit avoir au moins 1 caractère"
					],
					size: [
						value => !value || !value.length || value[0].size < 10485760 || 'La taille du fichier ne doit pas dépasser 10 Mo'
					]
				},
				search: null,
				total_peoples: 0,
				total_rows: 0
			}
		},
		emits: ['updatePeople'],
		methods: {
			cancel_delete_contact() {
				this.dialog.delete.show = false
			},
			cancel_import_contact() {
				this.dialog.import.show = false
			},
			cancel_new_contact() {
				this.dialog.edit.show = false
			},
			count_people() {
				let api = Api.get_instance()
				api.do_count_people()
					.then(total => {
						this.total_peoples = total
						this.$emit('updatePeople', this.total_peoples)
					})
			},
			create_new_contact() {
				this.dialog.edit.in_progress = true
				this.dialog.edit.create_button = "Création en cours"

				let api = Api.get_instance()
				api.do_create_people(this.contact, null)
					.then(() => {
						this.dialog.edit.show = false
						this.show_success("Le contact a été créé")
						this.fetch_data(this.options)
						this.total_peoples++
						this.$emit('updatePeople', this.total_peoples)
					})
					.catch(() => {
						this.dialog.edit.alert = true
						this.dialog.edit.alert_message = "Erreur lors de la création du contact"
					})
			},
			delayed_search() {
				if (this.delay !== null)
					window.clearTimeout(this.delay)
				this.delay = window.setTimeout(() => this.fetch_data(this.options), 1000)
			},
			delete_contact() {
				this.dialog.delete.delete_button = "Suppression en cours"
				this.dialog.delete.in_progress = true

				let api = Api.get_instance()
				api.do_delete_people(this.contact)
					.then(() => {
						this.dialog.delete.show = false
						this.show_success("Le contact a été supprimé")
						this.fetch_data(this.options)
						this.total_peoples--
						this.$emit('updatePeople', this.total_peoples)
					})
					.catch(() => {
						this.dialog.delete.show = false
						this.show_error("Erreur lors de la suppression du contact")
					})
			},
			fetch_data(option) {
				if (this.delay !== null)
					window.clearTimeout(this.delay)
				this.delay = null
				this.loading = true

				let params = {
					'limit': option.itemsPerPage,
					'offset': option.itemsPerPage * (option.page - 1)
				}

				let search = this.search && this.search.trim() || ''
				if (search.length > 0)
					params['filter'] = search

				if (option.sortBy.length > 0)
					params['order_by'] = option.sortBy.map((elt) => elt.order == 'desc' ? elt.key.toUpperCase() : elt.key).join(',')

				let api = Api.get_instance()
				api.do_list_peoples(params)
					.then(response => {
						this.loading = false

						this.peoples = response.peoples
						this.total_rows = response.total_rows
					})
					.catch(() => {
						this.loading = false
						this.show_error("Erreur lors de la récupération des contacts")
					})
			},
			import_contact() {
				if (this.dialog.import.file == null)
					return

				this.dialog.import.in_progress = true
				this.dialog.import.message = "Importation en cours"

				this.dialog.import.file.bytes()
					.then(data => {
						let api = Api.get_instance()
						api.do_import_people(data)
							.then(() => {
								this.dialog.import.show = false
								this.show_success("Les contacts ont été importés")
								this.fetch_data(this.options)
							})
							.catch(() => {
								this.dialog.import.alert = true
								this.dialog.import.alert_message = "Erreur lors de l'importation du fichier"
								this.dialog.import.in_progress = false
								this.dialog.import.message = "Importer"
								this.count_people()
							})
					})
					.catch(() => {
						this.dialog.import.alert = true
						this.dialog.import.alert_message = "Erreur en ouvrant le fichier"
						this.dialog.import.in_progress = false
						this.dialog.import.message = "Importer"
					})
			},
			show_create_new_contact() {
				this.contact.email = null
				this.contact.first_name = null
				this.contact.id = null
				this.contact.last_name = null
				this.contact.phone_number = null
				this.dialog.edit.alert = false
				this.dialog.edit.create = true
				this.dialog.edit.create_button = "Créer"
				this.dialog.edit.in_progress = false
				this.dialog.edit.show = true
				this.dialog.edit.title = "Ajouter un nouveau contact"
				this.dialog.edit.update = false
				this.dialog.edit.valid = false
			},
			show_delete_contact(people) {
				this.contact.email = people.email
				this.contact.first_name = people.first_name
				this.contact.id = people.id
				this.contact.last_name = people.last_name
				this.contact.phone_number = people.phone_number
				this.dialog.delete.delete_button = "Supprimer"
				this.dialog.delete.in_progress = false
				this.dialog.delete.show = true
			},
			show_error(msg) {
				this.alert.color = 'red'
				this.alert.message = msg
				this.alert.show = true
				this.alert.type = 'error'
			},
			show_import_contact() {
				this.dialog.import.alert = false
				this.dialog.import.file = null
				this.dialog.import.message = "Importer"
				this.dialog.import.show = true
			},
			show_success(msg) {
				this.alert.color = 'green'
				this.alert.message = msg
				this.alert.show = true
				this.alert.type = 'success'
			},
			show_update_contact(people) {
				this.contact.email = people.email
				this.contact.first_name = people.first_name
				this.contact.id = people.id
				this.contact.last_name = people.last_name
				this.contact.phone_number = people.phone_number
				this.dialog.edit.alert = false
				this.dialog.edit.create = false
				this.dialog.edit.in_progress = false
				this.dialog.edit.show = true
				this.dialog.edit.title = "Édition du contact"
				this.dialog.edit.update = true
				this.dialog.edit.update_button = "Modifier",
				this.dialog.edit.valid = false
			},
			update_contact() {
				this.dialog.edit.in_progress = true
				this.dialog.edit.update_button = "Modification en cours"

				let api = Api.get_instance()
				api.do_update_people(this.contact)
					.then(() => {
						this.dialog.edit.show = false
						this.show_success("Le contact a été mis à jour")
						this.fetch_data(this.options)
					})
					.catch(() => {
						this.dialog.edit.alert = true
						this.dialog.edit.alert_message = "Erreur lors de la mise à jour du contact"
					})
			}
		},
		mounted() {
			this.count_people()
		},
		name: 'AddressBook'
	}
</script>
