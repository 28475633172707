import { createRouter, createWebHashHistory } from 'vue-router'
import AccountView from '../views/Account.vue'
import Api from '@/Api'
import ConnectionView from '@/views/Connection.vue'
import CustomerView from '@/views/Customer.vue'
import HomeView from '@/views/Home.vue'
import LegalView from '@/views/LegalView.vue'
import LostPasswordView from '@/views/LostPassword.vue'
import MessageView from '@/views/Message.vue'

const routes = [{
	component: AccountView,
	meta: {
		requiresAuth: true
	},
	name: 'account',
	path: '/account/'
}, {
	component: MessageView,
	meta: {
		requiresAuth: true
	},
	name: 'message',
	path: '/account/message/'
}, {
	component: ConnectionView,
	meta: {
		requiresAuth: false
	},
	name: 'connection',
	path: '/connection/'
}, {
	component: CustomerView,
	meta: {
		requiresAuth: false
	},
	name: 'customer',
	path: '/customer/:uuid'
}, {
	meta: {
		requiresAuth: true
	},
	name: 'disconnection',
	path: '/disconnection/'
}, {
	component: HomeView,
	meta: {
		requiresAuth: false
	},
	name: 'home',
	path: '/'
}, {
	component: LegalView,
	meta: {
		requiresAuth: false
	},
	name: 'legal',
	path: '/legal/'
}, {
	component: LostPasswordView,
	meta: {
		requiresAuth: false
	},
	name: 'lost-password',
	path: '/lost-password/:token'
}]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, _, saved_position) {
		if (saved_position)
			return saved_position
		else if (to.hash)
			return { el: to.hash }
	}
})

router.beforeEach((to) => {
	let api = Api.get_instance()
	if (to.meta.requiresAuth) {
		if (!api.has_token())
			return {
				name: 'connection'
			}
		else if (to.name == 'disconnection') {
			api.do_log_out()
			return {
				name: 'home'
			}
		}
	} else if (to.name == 'connection' && api.has_token()) {
		return {
			name: 'home'
		}
	}
})

export default router
