<template>
	<header id="header" />

	<nav id="nav">
		<ul>
			<li><router-link :class="{ active: is_active[0] }" to="/account/message/#address-book">Carnet d'adresse</router-link></li>
			<li><router-link :class="{ active: is_active[1] }" to="/account/message/#message">Message</router-link></li>
			<li><router-link :class="{ active: is_active[2] }" to="/account/message/#qt-code">QrCode</router-link></li>
		</ul>
	</nav>

	<!-- Main -->
	<div id="main">
		<section id="address-book" class="main" v-show="this.is_active[0]">
			<AddressBook @update-people="(n) => total = n" />
		</section>

		<section id="message" class="main" v-show="this.is_active[1]">
			<SmsMessage :total_peoples="total" />
		</section>

		<section id="qt-code" class="main" v-show="this.is_active[2]">
			<v-container>
				<v-row>
					<v-spacer />
					<img id="img-qr-code" />
					<v-spacer />
				</v-row>
			</v-container>
		</section>
	</div>

</template>

<script>
	import AddressBook from '@/components/AddressBook.vue';
	import Api from '@/Api.js'
	import SmsMessage from '@/components/SmsMessage.vue';

	// @ is an alias to /src
	export default {
		beforeRouteUpdate(to) {
			this.is_active[0] = to.fullPath == "/account/message/#address-book" || to.hash == ""
			this.is_active[1] = to.fullPath == "/account/message/#message"
			this.is_active[2] = to.fullPath == "/account/message/#qt-code"
		},
		components: {
			AddressBook,
			SmsMessage
		},
		data() {
			return {
				is_active: [true, false, false],
				total: 0
			}
		},
		mounted() {
			this.is_active[0] = this.$route.fullPath == "/account/message/#address-book" || this.$route.hash == ""
			this.is_active[1] = this.$route.fullPath == "/account/message/#message"
			this.is_active[2] = this.$route.fullPath == "/account/message/#qt-code"

			let api = Api.get_instance()
			api.do_get_qr_code()
				.then(blob => {
					let img = document.querySelector('#img-qr-code')
					img.src = URL.createObjectURL(blob)
				})
		},
		name: 'MessageView'
	}
</script>
